import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { CapGuideClientApi } from '../api/CapGuideApi';
import jwt_decode from "jwt-decode";

export const UserContext = createContext();

export const UserProvider = (props) => {
    const [user, setUser] = useState(null);

    const [capGuideClientToken, setCapGuideClientToken] = useState(null);

    const [userLoading, setUserLoading] = useState(true);

    let history = useHistory();
    let location  = useLocation();

    useEffect(() => {

        let localStorageCapGuideClientToken = localStorage.getItem('CAP_GUIDE_CLIENT_TOKEN');

        if(localStorageCapGuideClientToken !== undefined && localStorageCapGuideClientToken !== null){
            axios.get(process.env.REACT_APP_CAP_GUIDE_CLIENT_API_BASE_URL + '/verifyToken', {
                headers: {
                    'Authorization': 'Bearer ' + localStorageCapGuideClientToken
                }
            })
            .then(res => {
                CapGuideClientApi.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageCapGuideClientToken;
                setCapGuideClientToken(localStorageCapGuideClientToken);
                let decoded_token = jwt_decode(localStorageCapGuideClientToken);
                setUser(decoded_token)
            })
            .catch(err => {
                CapGuideClientApi.defaults.headers.common['Authorization'] = undefined;
                setCapGuideClientToken(null);
                localStorage.removeItem('CAP_GUIDE_CLIENT_TOKEN');
                // history.push('/account/login');
            })
            .finally(() => setUserLoading(false))
        } else {
            setUserLoading(false)
            setCapGuideClientToken(null);
        }

    }, [])

    const loginUser = async (loginInfo) => {
        setUserLoading(true);

        try{

            const tokenRequest = await axios.post(process.env.REACT_APP_CAP_GUIDE_CLIENT_API_BASE_URL + '/login', loginInfo)

            // When a user signs in with a temp password the server responds with a 307 status. The user needs to be redirected to the complete sign up page
            if(tokenRequest.status === 307)
                return history.push('/account/new-password?email=' + encodeURIComponent(loginInfo.email) + '&temp-password=' + encodeURIComponent(loginInfo.password))
            
            CapGuideClientApi.defaults.headers.common['Authorization'] = 'Bearer ' + tokenRequest.data.token;
            localStorage.setItem('CAP_GUIDE_CLIENT_TOKEN', tokenRequest.data.token)
            toast.success('User Logged In');
            setCapGuideClientToken(tokenRequest.data.token);
            setUser(jwt_decode(tokenRequest.data.token));
            
        } catch(err){
            console.log(err)
            if(err.response.status === 307)
                return history.push('/account/new-password?email=' + encodeURIComponent(loginInfo.email) + '&temp-password=' + encodeURIComponent(loginInfo.password))
            setCapGuideClientToken(null);
            CapGuideClientApi.defaults.headers.common['Authorization'] = undefined;
            toast.error('Error: Login failed');
        } finally {   
            setUserLoading(false)
        }
	}

    const logoutUser = () => {
        CapGuideClientApi.defaults.headers.common['Authorization'] = undefined;
        localStorage.removeItem('CAP_GUIDE_CLIENT_TOKEN');
        setCapGuideClientToken(null)
        toast.success('User Logged Out');
        history.push('/account/login');
    }

    // const logoutUser = () => {
    //     localStorage.removeItem('OMNI_TOKEN');
    //     OmniApi.defaults.headers.common['token'] = undefined;
    //     XOmniApi.defaults.headers.common['token'] = undefined;
    //     toast.success("User logged out", {
    //         theme: 'colored',
    //         position: "top-center",
    //         autoClose: 5000,
    //         hideProgressBar: true,
    //         closeOnClick: false,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //     });
    //     setUser('');
    //     history.push('/account/login');
    //     // !!!!!!!!!!!!!!!!!!! IMPORTANT: REMOVE TOKEN FROM AXIOS DEFAULTS. OMNI API !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // } 


    
    return (
        <UserContext.Provider value={{ user, setUser, userLoading, loginUser, logoutUser, capGuideClientToken, setCapGuideClientToken }}>
            {props.children}
        </UserContext.Provider>
    );
}

