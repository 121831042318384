import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Table, Row, Col, Card, Badge, CardBody } from 'reactstrap';
import { CapGuideClientApi } from '../api/CapGuideApi';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import arrowsVertical from '@iconify/icons-carbon/arrows-vertical';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import arrowDown from '@iconify/icons-carbon/arrow-down';
import Helmet from 'react-helmet';

const Deals = props => {

    const [deals, setDeals] = useState(null);

    const [dealStages, setDealStages] = useState([]);

    useEffect(() => {

        CapGuideClientApi.get('/deals')
        .then(res => setDeals(res.data))
        .catch(err => toast.error('Error fetching deals'))

        CapGuideClientApi.get('/dealStages')
        .then(res => setDealStages(res.data))
        .catch(err => toast.error('Error fetching deal stages'))
        
    }, [])

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      const [sortBy, setSortBy] = useState({
        sortField: 'createdate',
        sortDirection: 'asc'
    })

    function sortByField(array){
        switch(sortBy.sortField){
            case 'createdate': 
                if(sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => new Date(a.properties.createdate) > new Date(b.properties.createdate) ? -1 : 1)
                else
                    return array.sort((a, b) => new Date(a.properties.createdate) < new Date(b.properties.createdate) ? -1 : 1)
            case 'loan_amount': 
                if(sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => parseInt(a.properties.loan_amount) < parseInt(b.properties.loan_amount) ? -1 : 1)
                else
                    return array.sort((a, b) => parseInt(a.properties.loan_amount) > parseInt(b.properties.loan_amount) ? -1 : 1)
            case 'dealname': 
                if(sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.properties.dealname < b.properties.dealname ? -1 : 1)
                else
                    return array.sort((a, b) => a.properties.dealname > b.properties.dealname ? -1 : 1)
        }
    }

    function onClickSort(field){
        if(field === sortBy.sortField){
            setSortBy({
                ...sortBy,
                sortDirection: sortBy.sortDirection === 'asc' ? 'desc' : 'asc'
            })
        } else {
            setSortBy({
                sortField: field,
                sortDirection: 'asc'
            })
        }
    }

    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ? 
            <Icon icon={arrowUp} color="black"/>
            :
            <Icon icon={arrowDown} color="black"/>
            }
        </>
    )

    if(!deals)
    return (
        <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
    <div>
    <h4>Loading Deals...</h4>
        <div className="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
        </div>
        </div>
    </div>
    )

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Deals | {process.env.REACT_APP_HEADER_TITLE}</title>
        </Helmet>
        <Row>
            <Col>
                <Card className='mt-2'>
                    <CardBody className='pt-1'>
                    <h2>Deals</h2>
                <Table className="mb-0 mt-0" hover responsive>
                    <thead>
                        <tr>
                            <th style={{ whiteSpace: 'nowrap' }} onClick={() => onClickSort('dealname')}>Deal Name {sortBy.sortField === 'dealname' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Deal Stage </th>
                            <th style={{ whiteSpace: 'nowrap' }} onClick={() => onClickSort('loan_amount')}>Loan Amount {sortBy.sortField === 'loan_amount' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Deal ID</th>
                            <th style={{ whiteSpace: 'nowrap' }} onClick={() => onClickSort('createdate')}>Date {sortBy.sortField === 'createdate' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortByField(deals).map((deal, index) => {
                            return (
                                <tr 
                                onClick={() => props.history.push('/deals/' + deal.id)}
                                key={index}>
                                    <th scope="row">{deal.properties.dealname}</th>
                                    <td>
                                    <Badge 
                                        pill
                                        style={{ fontSize: '14px' }}
                                        className={`badge badge-outline-primary`}>
                                            {dealStages.find(stage => stage.id === deal.properties.dealstage)?.label}
                                    </Badge>
                                    </td>
                                    <td><b>{formatter.format(deal.properties.loan_amount)}</b></td>
                                    <td>{deal.id}</td>
                                    <td>{new Date(deal.createdAt).toLocaleString()}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                {!deals.length && <h5 className='text-center mt-3'>No Deals to Display</h5>}
                </CardBody>
                </Card>
            </Col>
        </Row>
        </>
    )
}

export default Deals;