import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { CapGuideClientApi } from '../api/CapGuideApi';
import { Card, Row, Col, CardBody, Badge, CardHeader, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import handshakeOutline from '@iconify/icons-mdi/handshake-outline';
import cashIcon from '@iconify/icons-mdi/cash';
import walletOutline from '@iconify/icons-mdi/wallet-outline';
import chartTimelineVariant from '@iconify/icons-mdi/chart-timeline-variant';
import fileSign from '@iconify/icons-mdi/file-sign';
import officeBuilding from '@iconify/icons-mdi/office-building';
import PageTitle from '../components/PageTitle';
import CircularProgressBar from '../utility-components/CircularProgressBar';
import Select from 'react-select';

const DealDetail = props => {

    const [deal, setDeal] = useState(null);

    const [dealStages, setDealStages] = useState(null);

    const [activeStage, setActiveStage] = useState(null);

    const [pipelineDisplayId, setPipelineDisplayId] = useState(null);

    useEffect(() => {
        if(dealStages && deal)
            setActiveStage(dealStages.find(stage => stage.id === deal.properties.dealstage))
    }, [dealStages, deal])

    useEffect(() => {
        if(activeStage?.pipelineId)
            setPipelineDisplayId(activeStage.pipelineId)
    }, [activeStage])

    let dealId = props.match.params.dealId;

    let preSalesPipelineId = '10586526';

    let postSalesPipelineId = '10496823';

    let dealLostStageId = '30786965';
    let onHoldStageId = '33563425';

    let postClosingId = "33579158";

    let dealWonStageId = "30786964";

    useEffect(() => {
        CapGuideClientApi.get('/deals/' + dealId)
        .then(res => setDeal(res.data))
        .catch(err => toast.error('Error fetching deal'))

        CapGuideClientApi.get('/dealStages')
        .then(res => {
            let tempStages = res.data;
            // remove deal lost and on hold stages from pre sales pipeline
            tempStages.find(stage => stage.id === dealLostStageId).pipelineId = '';
            tempStages.find(stage => stage.id === onHoldStageId).pipelineId = '';
            // let tempStages = res.data.filter(stage => stage.id !== dealLostStageId && stage.id !== onHoldStageId)
            // assign new displayOrders to each pipeline
            tempStages.filter(stage => stage.pipelineId === preSalesPipelineId).sort((a, b) => a.displayOrder - b.displayOrder).forEach((stage, i) => stage.displayOrder = i + 1)
            tempStages.filter(stage => stage.pipelineId === postSalesPipelineId).sort((a, b) => a.displayOrder - b.displayOrder).forEach((stage, i) => stage.displayOrder = i + 1)
            console.log(tempStages)
            setDealStages(tempStages)
        })
        .catch(err => toast.error('Error fetching deal stages'))

    }, [])

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    
    function preSalesPipelineStatus(stage){
        if(stage.pipelineId === postSalesPipelineId){
            return 100
        } else if (stage.pipelineId === preSalesPipelineId){
            // get number of presalestages
            let preSalesStages = dealStages.filter(stage => stage.pipelineId === preSalesPipelineId).length;
            // percentage points per step
            let stepPercentage = 100 / (preSalesStages - 1);
            
            return stepPercentage * (stage.displayOrder - 1);
        } else {
            return 100
        }
    }

    function postSalesPipelineStatus(stage){
        if(stage.pipelineId === preSalesPipelineId){
            return 0
        } else if (stage.pipelineId === postSalesPipelineId){
            // get number of stages
            let postSaleStages = dealStages.filter(stage => stage.pipelineId === postSalesPipelineId).length;
            // percentage points per step
            let stepPercentage = 100 / (postSaleStages - 1);
            console.log(stepPercentage)
            return stepPercentage * (stage.displayOrder - 1);
        } else {
            return 100
        }
    }

    let colors = {
        green: '#00BDA5',
        red: '#fb5858',
        yellow: '#ffbc00',
        lightSecondary: '#969DA4',
        blue: '#377dff'
    }

    function preSalesStepStatus(stage, currentStage){
        if(currentStage.pipelineId === postSalesPipelineId){
            return colors.green
        } else if(currentStage.pipelineId === preSalesPipelineId){
            if(currentStage.id === dealWonStageId)
                return colors.green
            else if(currentStage.displayOrder === stage.displayOrder)
                return colors.blue
            return currentStage.displayOrder > stage.displayOrder ? colors.blue : colors.lightSecondary
        } else {
            if(currentStage.id === dealLostStageId)
                return colors.red
            else if(currentStage.id === onHoldStageId)
                return colors.yellow
        }
    }

    function postSalesStepStatus(stage, currentStage){
        if(currentStage.pipelineId === preSalesPipelineId){
            return colors.lightSecondary
        } else if(currentStage.pipelineId === postSalesPipelineId){
            if(currentStage.id === postClosingId)
                return colors.green
            if(currentStage.displayOrder === stage.displayOrder)
                return colors.blue
            return currentStage.displayOrder > stage.displayOrder ? colors.blue : colors.lightSecondary
        } else {
            if(currentStage.id === dealLostStageId)
                return colors.red
            else if(currentStage.id === onHoldStageId)
                return colors.yellow
        }
    }

    function preSalesProgressBarColor(currentStage){
        if(currentStage.id === dealLostStageId)
                return '#fa5c7c'
        else if(currentStage.id === onHoldStageId)
            return '#ffbc00'
        else if(currentStage.pipelineId === postSalesPipelineId){
            return colors.green
        } else if(currentStage.pipelineId === preSalesPipelineId){
            if(currentStage.id === dealWonStageId)
                return colors.green
            else
                return colors.blue
        }
    }

    function postSalesProgressBarColor(currentStage){
        if(currentStage.id === dealLostStageId)
                return '#fa5c7c'
        else if(currentStage.id === onHoldStageId)
            return '#ffbc00'
        else if(currentStage.pipelineId === postSalesPipelineId){
            if(currentStage.id === postClosingId)
                return colors.green
            else
                return colors.blue
        }
    }

    function nextStepDisplay(currentStage){
        if(currentStage.id === dealWonStageId)
            return 'Starting Bank Underwriting'
        else
            return dealStages.find(stage => stage.pipelineId === activeStage.pipelineId && stage.displayOrder === activeStage.displayOrder + 1).label
    }

    const propertyTypes = [
        {
            value: "1-4 family",
            label: "1-4 Family"
        },
        {
            value: 'co-op',
            label: 'Co-Op'
        },
        {
            value: 'condominium',
            label: 'Condominium'
        },
        {
            value: 'health care',
            label: 'Health Care'
        },
        {
            value: 'hotels/hospitality',
            label: 'Hotels/Hospitality'
        },
        {
            value: 'industrial',
            label: 'Industrial'
        },
        {
            value: 'land',
            label: 'Land'
        },
        {
            value: 'mixed-use',
            label: 'Mixed-Use'
        },
        {
            value: 'multi-family',
            label: 'Multi-Family'
        },
        {
            value: 'office',
            label: 'Office'
        },
        {
            value: 'owner-occupied',
            label: 'Owner-Occupied'
        },
        {
            value: 'retail',
            label: 'Retail'
        },
        {
            value: 'sfr',
            label: 'SFR'
        },
        {
            value: 'special purpose',
            label: 'Special Purpose'
        },
        {
            value: 'sro',
            label: 'SRO'
        }
    ];
    
    function capitalizeFirstLetter(string = '') {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      
    if(!deal || !dealStages || !activeStage)
    return (
        <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
    <div>
    <h4>Loading Deal Details...</h4>
        <div className="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
        </div>
        </div>
    </div>
    )

    const pipelineDisplayOptions = [
        {
            label: 'Pre-Sales Pipeline',
            value: '10586526'
        },
        {
            label: 'Post-Sales Pipeline',
            value: '10496823'
        }
    ]
    
    return(
        <div className='w-100 mt-2'>
            {/* <PageTitle
                breadCrumbItems={[
                    { label: 'CapGuide Portal', path: '/' },
                    { label: 'Deals', path: '/deals' },
                    { label: `${deal.properties.dealname}`, path: '', active: true },
                ]}
            /> */}
            <Row className='d-block d-md-none'>
                <Col xs={12}>
                    {/* <Card>
                        <CardBody> */}
                        <Card>
                            {true &&
                            <Row className='d-flex justify-content-center mt-3'>
                                <Col xs={10}>
                            <FormGroup className='mb-0'>
                                {/* <div style={{width: '250px' }} className="mr-0"> */}
                                    <Select
                                        isDisabled={!activeStage.pipelineId}
                                        classNamePrefix="react-select"
                                        name="pipeline"
                                        onChange={(selection) => {
                                            setPipelineDisplayId(selection.value)
                                        }}
                                        value={pipelineDisplayOptions.find(option => pipelineDisplayId === option.value)}
                                        isSearchable={false}
                                        options={pipelineDisplayOptions}
                                        placeholder={'Select pipeline...'}
                                    >
                                    </Select>
                                {/* </div> */}
                            </FormGroup>
                            </Col>
                            </Row>}
                            <Row className='d-flex justify-content-center'>
                                <Col>
                            {(pipelineDisplayId === preSalesPipelineId || !activeStage.pipelineId) &&
                            <div>
                                <div className='d-flex justify-content-center py-2'>
                                <CircularProgressBar value={preSalesPipelineStatus(activeStage)} 
                                text={activeStage.pipelineId === preSalesPipelineId ? 
                                    activeStage.label : 
                                    activeStage.pipelineId ?
                                    'Completed'
                                    :
                                    activeStage.label
                                } color={preSalesProgressBarColor(activeStage)}/>
                                </div>
                            </div>}
                            {pipelineDisplayId === postSalesPipelineId &&
                            <div>
                                <div className='d-flex justify-content-center py-2'>
                                <CircularProgressBar value={postSalesPipelineStatus(activeStage)} text={activeStage.pipelineId === postSalesPipelineId && activeStage.label} color={postSalesProgressBarColor(activeStage)}/>
                                </div>
                            </div>}
                            </Col>
                    {/* </CardBody>
                    </Card> */}
                    </Row>
                    </Card>
                </Col>
            </Row>
            <Row className='justify-content-center d-flex d-md-none'>
                <Col>
                <div style={{ height: '100%' }} className="">
                <Card className='my-2 shadow'>
                    <CardBody className='text-center'>
                        {(activeStage.id === onHoldStageId) &&
                            (
                                <>
                                    Current Status: <br />
                                <span style={{ fontSize: '18px' }}><b>On Hold</b></span>
                                </>
                            )
                        }
                        {(activeStage.id === dealLostStageId) &&
                            (
                                <>
                                    Current Status: <br />
                                <span style={{ fontSize: '18px' }}><b>Deal Lost</b></span>
                                </>
                            )
                        }
                        {(activeStage.id === postClosingId) &&
                            (
                                <>
                                <span style={{ fontSize: '18px' }}><b>Congratulations: Deal Closed</b></span>
                                </>
                            )
                        }
                        {(activeStage.id !== dealLostStageId && activeStage.id !== onHoldStageId && activeStage.id !== postClosingId) &&
                            (
                                <>
                                Next Step: <br />
                                <span style={{ fontSize: '18px' }}><b>{nextStepDisplay(activeStage)}</b></span>
                                </>
                            )
                        }
                    </CardBody>
                </Card>
                <div className='mt-1 mb-1' style={{ borderRadius: '0.25rem', overflow: 'hidden' }}>
                    <Card className='shadow'>
                        <CardHeader className='text-center'><h4 className='my-1'>Deal Info</h4></CardHeader>
                        <CardBody>
                        <div className='ml-4'>
                            <p></p>
                            <p><Icon icon={handshakeOutline} width="20" /> Deal Name: <br /><strong>{deal.properties.dealname}</strong></p>
                            <p><Icon icon={cashIcon} width="20"/> Amount: <br /><strong>{formatter.format(deal.properties.loan_amount)}</strong></p>
                            <p><Icon icon={walletOutline} width="20"/> Loan Type: <br /><strong>{deal.properties.loan_type && capitalizeFirstLetter(deal.properties.loan_type)}</strong></p>
                            <p><Icon icon={fileSign} width="20"/> Deal Type: <br /><strong>{deal.properties.dealtype && capitalizeFirstLetter(deal.properties.dealtype)}</strong></p>
                            <p><Icon icon={officeBuilding} width="20" /> Property Type: <br /><strong>
                                {propertyTypes.find(property => property.value === deal.properties.property_type)?.label}
                            </strong></p>
                        </div> 
                        </CardBody>
                    </Card>
                </div>
                </div></Col>
            </Row>
            {/* VERTICAL STEPS START */}
            {/* <Row className="justify-content-center d-xs-block d-md-none">
                <Col style={{ maxWidth: '200px' }}>
                    <Card style={{ borderRadius: '11px' }} className="shadow position-relative mt-3 mb-4">
                        <CardBody className='progress-card-body'>
                    <h4 className='mt-0 mb-2 text-center'>Pre-Sales<br />Pipeline</h4>
                        <div className='d-flex flex-row align-items-center'>
                    <div className="vertical-steps my-3">
                        <div className="vertical-steps-content" style={{ minHeight: '500px' }}>
                            {dealStages.filter(stage => stage.pipelineId === preSalesPipelineId).map((stage, i) => {
                                return (
                                <StepItem stage={stage} activeStage={activeStage} stepStatus={preSalesStepStatus}/>
                            )})}
                        </div>

                        <div className="process-line" style={{ minWidth: `${preSalesPipelineStatus(activeStage) * 5}px`, backgroundColor: `${preSalesProgressBarColor(activeStage)}` }}></div>
                    </div>
                    </div>
                    </CardBody>
                    </Card>
                </Col>
                <Col>
                <div className='mb-0 d-flex flex-row justify-content-between overflow-hidden'>
                <div style={{ width: '350px', height: '100%' }} className="">
                <Card className='my-2 mr-2 ml-0 shadow'>
                    <CardBody className='text-center'>
                        {(activeStage.id === onHoldStageId) &&
                            (
                                <>
                                    Current Status: <br />
                                <span style={{ fontSize: '18px' }}><b>On Hold</b></span>
                                </>
                            )
                        }
                        {(activeStage.id === dealLostStageId) &&
                            (
                                <>
                                    Current Status: <br />
                                <span style={{ fontSize: '18px' }}><b>Deal Lost</b></span>
                                </>
                            )
                        }
                        {(activeStage.id === postClosingId) &&
                            (
                                <>
                                <span style={{ fontSize: '18px' }}><b>Congratulations: Deal Closed</b></span>
                                </>
                            )
                        }
                        {(activeStage.id !== dealLostStageId && activeStage.id !== onHoldStageId && activeStage.id !== postClosingId) &&
                            (
                                <>
                                Next Step: <br />
                                <span style={{ fontSize: '18px' }}><b>{nextStepDisplay(activeStage)}</b></span>
                                </>
                            )
                        }
                    </CardBody>
                </Card>
                <div className='mr-2 ml-0 mt-1 mb-1' style={{ borderRadius: '0.25rem', overflow: 'hidden' }}>
                    <Card className='shadow'>
                        <CardHeader className='text-center'><h4 className='my-1'>Deal Info</h4></CardHeader>
                        <CardBody>
                        <div className='ml-4'>
                            <p></p>
                            <p><Icon icon={handshakeOutline} width="20" /> Deal Name: <br /><strong>{deal.properties.dealname}</strong></p>
                            <p><Icon icon={cashIcon} width="20"/> Amount: <br /><strong>{formatter.format(deal.properties.loan_amount)}</strong></p>
                            <p><Icon icon={walletOutline} width="20"/> Loan Type: <br /><strong>{deal.properties.loan_type && capitalizeFirstLetter(deal.properties.loan_type)}</strong></p>
                            <p><Icon icon={fileSign} width="20"/> Deal Type: <br /><strong>{deal.properties.dealtype && capitalizeFirstLetter(deal.properties.dealtype)}</strong></p>
                            <p><Icon icon={officeBuilding} width="20" /> Property Type: <br /><strong>
                                {propertyTypes.find(property => property.value === deal.properties.property_type)?.label}
                            </strong></p>
                        </div> 
                        </CardBody>
                    </Card>
                </div>
                </div>
            </div>
                </Col>
                <Col style={{ maxWidth: '200px' }}>
                    <Card style={{ borderRadius: '11px' }} className="shadow position-relative mt-3 mb-4">
                        <CardBody className='progress-card-body'>
                    <h4 className='mt-0 mb-2 text-center'>Post-Sales<br />Pipeline</h4>
                        <div className='d-flex flex-row align-items-center'>
                    <div className="vertical-steps my-3">
                        <div className="vertical-steps-content" style={{ minHeight: '500px' }}>
                            {dealStages.filter(stage => stage.pipelineId === postSalesPipelineId).map((stage, i) => {
                                return (
                                <StepItem stage={stage} activeStage={activeStage} stepStatus={postSalesStepStatus}/>
                            )})}
                        </div>

                        <div className="process-line" style={{ minWidth: `${postSalesPipelineStatus(activeStage) * 5}px`, backgroundColor: `${postSalesProgressBarColor(activeStage)}` }}></div>
                    </div>
                    </div>
                    </CardBody>
                    </Card>
                </Col>
            </Row> */}
            {/* VERTICAL STEPS END */}
            <Row className="justify-content-center d-none d-md-block">
                <Col sm={12}>
                    <Card style={{ borderRadius: '11px' }} className="shadow position-relative mt-3 mb-4">
                        <CardBody className='progress-card-body'>
                    <h4 className='mt-0 mb-2 text-center'>Pre-Sales Pipeline</h4>
                        <div className='d-flex flex-row align-items-center'>
                    <div className="horizontal-steps mx-3">
                        <div className="horizontal-steps-content">
                            {dealStages.filter(stage => stage.pipelineId === preSalesPipelineId).map((stage, i) => {
                                return (
                                <StepItem stage={stage} activeStage={activeStage} stepStatus={preSalesStepStatus}/>
                            )})}
                        </div>

                        <div className="process-line" style={{ width: `${preSalesPipelineStatus(activeStage)}%`, backgroundColor: `${preSalesProgressBarColor(activeStage)}` }}></div>
                    </div>
                    </div>
                    <h4 className='mb-2 mt-4 text-center'>Post-Sales Pipeline</h4>
                    <div className='d-flex flex-row align-items-center'>
                    <div className="horizontal-steps mx-3 mb-2">
                        <div className="horizontal-steps-content">
                            {dealStages.filter(stage => stage.pipelineId === postSalesPipelineId).map((stage, i) => {
                                return (
                                <StepItem stage={stage} activeStage={activeStage} stepStatus={postSalesStepStatus}/>
                            )})}
                        </div>

                        <div className="process-line" style={{ width: `${postSalesPipelineStatus(activeStage)}%`, backgroundColor: `${postSalesProgressBarColor(activeStage)}` }}></div>
                    </div>
                    </div>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
            {/* <Row className="justify-content-center">
                <Col sm={12}>
                    <Card style={{ height: '100px', borderRadius: '11px' }} className="d-flex flex-row align-items-center shadow position-relative">
                        <div className='position-absolute w-100 text-center' style={{ top: '10px', fontSize: '18px' }}><b>Post-Sales Pipeline</b></div>
                    <div className="horizontal-steps mx-3 mt-3">
                        <div className="horizontal-steps-content">
                            {dealStages.filter(stage => stage.pipelineId === postSalesPipelineId).map((stage, i) => {
                                return (
                                <StepItem stage={stage} currentStageId={deal.properties.dealstage}/>
                            )})}
                        </div>

                        <div className="process-line" style={{ width: '75%', backgroundColor: '#03bda5' }}></div>
                    </div>
                    </Card>
                </Col>
            </Row> */}
            {/* <Row className="justify-content-center">
                <Col sm={12}>
                <Card style={{ height: '100px', borderRadius: '11px' }} className="d-flex flex-row align-items-center shadow position-relative">
                <div className='position-absolute w-100 text-center' style={{ top: '10px', fontSize: '18px' }}><b>Post-Sale Pipeline</b></div>
                    <div className="horizontal-steps mx-3">
                        <div className="horizontal-steps-content">
                            {dealStages.map((stage, i) => {
                                if (i >= 7)
                                return (
                                    <StepItem stage={stage} currentStageId={deal.properties.dealstage} currentStageIndex={currentStageIndex}/>
                            )})}
                        </div>

                        <div className="process-line" style={{ width: '57%' }}></div>
                    </div>
                    </Card>
                </Col>
            </Row> */}
            <div className='mb-0 flex-row justify-content-between overflow-hidden d-none d-md-flex'>
                <div style={{ width: '350px', height: '100%' }} className="">
                <Card className='my-2 mr-2 ml-0 shadow'>
                    <CardBody className='text-center'>
                        {(activeStage.id === onHoldStageId) &&
                            (
                                <>
                                    Current Status: <br />
                                <span style={{ fontSize: '18px' }}><b>On Hold</b></span>
                                </>
                            )
                        }
                        {(activeStage.id === dealLostStageId) &&
                            (
                                <>
                                    Current Status: <br />
                                <span style={{ fontSize: '18px' }}><b>Deal Lost</b></span>
                                </>
                            )
                        }
                        {(activeStage.id === postClosingId) &&
                            (
                                <>
                                <span style={{ fontSize: '18px' }}><b>Congratulations: Deal Closed</b></span>
                                </>
                            )
                        }
                        {(activeStage.id !== dealLostStageId && activeStage.id !== onHoldStageId && activeStage.id !== postClosingId) &&
                            (
                                <>
                                Next Step: <br />
                                <span style={{ fontSize: '18px' }}><b>{nextStepDisplay(activeStage)}</b></span>
                                </>
                            )
                        }
                    </CardBody>
                </Card>
                <div className='mr-2 ml-0 mt-1 mb-1' style={{ borderRadius: '0.25rem', overflow: 'hidden' }}>
                    <Card className='shadow'>
                        <CardHeader className='text-center'><h4 className='my-1'>Deal Info</h4></CardHeader>
                        <CardBody>
                        <div className='ml-4'>
                            <p></p>
                            <p><Icon icon={handshakeOutline} width="20" /> Deal Name: <br /><strong>{deal.properties.dealname}</strong></p>
                            <p><Icon icon={cashIcon} width="20"/> Amount: <br /><strong>{formatter.format(deal.properties.loan_amount)}</strong></p>
                            <p><Icon icon={walletOutline} width="20"/> Loan Type: <br /><strong>{deal.properties.loan_type && capitalizeFirstLetter(deal.properties.loan_type)}</strong></p>
                            {/* <p><Icon icon={chartTimelineVariant} width="20"/> Deal Stage: <br /><strong>{dealStages.find(stage => stage.id === deal.properties.dealstage)?.label}</strong></p> */}
                            <p><Icon icon={fileSign} width="20"/> Deal Type: <br /><strong>{deal.properties.dealtype && capitalizeFirstLetter(deal.properties.dealtype)}</strong></p>
                            <p><Icon icon={officeBuilding} width="20" /> Property Type: <br /><strong>
                                {propertyTypes.find(property => property.value === deal.properties.property_type)?.label}
                            </strong></p>
                        </div> 
                        </CardBody>
                    </Card>
                </div>
                </div>
                <Card style={{ width: '250px', minHeight: '100%', flex: '1' }} className="shadow ml-3 mt-2 mb-3 d-flex align-items-center justify-content-center mr-0">
                    <h3 className='mb-4'><i>Additional Features Coming Soon...</i></h3>
                </Card>
            </div>
        </div>
    )
}

const StepItem = ({stage, activeStage, stepStatus}) => {

    const [hovered, setHovered] = useState(false);

    return(
        <div 
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={`step-item stage-step ${stage.id === activeStage.id && 'current'}`}
        style={{ color: `${stepStatus(stage, activeStage)}` }}
        >
            {(stage.id === activeStage.id || hovered) && <span 
            className='shadow-lg'
            style={{ color: `#6c757d`, background: 'white', paddingLeft: '7px', paddingRight: '7px', borderRadius: '5px', whiteSpace: 'nowrap' }}
            ><b>{stage.label}</b></span>}
        </div>
    )
}

export default DealDetail;