import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './CircularProgressBar.css';

const CircularProgressBar = ({ value, text, color }) => {

    const percentage = 66;

    function textWithBreaks(text){

        if(!text)
            return

        let splitText = text.trim().split(' ');

        return (<>
           {splitText.map(word => (
            <>
                {word}
                <br />
            </>
            ))} 
        </>)
    }

    return(
        <div style={{ width: 170, height: 170 }}>
            <div
            style={{
                position: "relative",
                width: "100%",
                height: "100%"
            }}
            >
            <div style={{ position: "absolute" }}>
                <CircularProgressbar
                value={value} 
                styles={{
                    path: {
                        // stroke: `rgba(62, 152, 199, ${value / 100})`,
                        stroke: color
                    },
                    // trail: {
                    //     stroke: '#d6d6d6'
                    // },
                    text: {
                        // Text color
                        // fill: '#f88',
                        // maxWidth: '50px',
                        // whiteSpace: 'normal',
                        // Text size
                        // fontSize: '12px',
                      },
                }}
                />
            </div>
            <div
                className='text-center'
                style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: '17px',
                fontWeight: '600',
                lineHeight: '19px'
                }}
            >
                {textWithBreaks(text)}
            </div>
            </div>
        </div>
    )
}

export default CircularProgressBar;