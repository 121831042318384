import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link, useLocation, useHistory } from 'react-router-dom';

import { Container, Row, Col, Card, CardBody, FormGroup, Button } from 'reactstrap';

import { isUserAuthenticated } from '../../helpers/authUtils';
import logo from '../../assets/images/logo-on-white.svg';
import emailImg from '../../assets/images/mail_sent.svg';
import { UserContext } from '../../contexts/UserContext';
import { Icon } from '@iconify/react';
import accountCheck from '@iconify/icons-mdi/account-check';
import Helmet from 'react-helmet';

const ConfirmNewUser = () => {

    let history = useHistory();
    let location = useLocation();
    const query = new URLSearchParams(location.search);

    const { capGuideClientToken } = useContext(UserContext);

    useEffect(() => {
        if(!query.get('email'))
            history.replace('/account/login')
        if(capGuideClientToken)
            history.replace(process.env.REACT_APP_DEFAULT_REDIRECT_ROUTE)
    }, [capGuideClientToken])

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Registration Complete | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
                <>
                <Row className='auth-window m-0'>
                    <Col xs={12} sm={6} md={5} lg={4} className="p-0 d-flex flex-column justify-content-between bg-auth">
                        <div className='d-flex w-100 justify-content-center mt-4'>
                        <img src={logo} alt="" height="50" />
                        </div>
                        <div className='mx-4' style={{color: '#00307c'}}>
                                            <h2 className="text-formalNavy text-center mt-0 font-weight-bold mb-3">
                                                Registration Complete
                                            </h2>
                                    <Row className='justify-content-center'>
                                        <Col md={10}>

                                        <div className="text-center m-auto">
                                            <Icon icon={accountCheck} color="formalNavy" height="85"/>
                                            <div><b>{query.get('email')}</b></div>
                                            <p className="text-skyBlue mb-4">
                                                Please login with your new password
                                            </p>

                                        </div>

                                        <FormGroup className="mt-3">
                                                <Button block color="formalNavy" onClick={() => history.push('/account/login')}>Back to Login</Button>
                                        </FormGroup>
                                        
                                        
                                        </Col>
                                    </Row>
                                {/* <Row className="mt-1">
                                <Col className="col-12 text-center">
                                    <p style={{ color: 'rgba(92,146,255,1)' }}>
                                        Don't have an account?{' '}
                                        <Link to="/account/register" className="ml-1" color='rgba(92,146,255,1)'>
                                            <b>Register</b>
                                        </Link>
                                    </p>
                                </Col>
                            </Row> */}
                            </div>
                            <div className='h-25'></div>
                    </Col>
                    <Col sm={6} md={7} lg={8} className='bg-column p-0 d-none d-sm-block'></Col>
                </Row>
            </>
        </React.Fragment>
    );
}

export default connect()(ConfirmNewUser);
